function ContactoPopUP() {


    return (
        <>
            <div class="fixed-tabs-Whats">
                <div class="aba-whatsphone">
                    <div class="aba-whatsphone-icone">
                        <a target="_blank" href="https://api.whatsapp.com/send?phone=593999051864">Quieres<br/><strong>Contratar?</strong></a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ContactoPopUP;
